import * as constand from '../constant';
import { getData, postData } from '../_helpers/api-helper';
import handleResponse from '../_services/handle.service';
import { GET_BLOG_TITLES,WELCOME_CONDITION_MODEL_BY_BLOG,WELCOME_CONDITION_MODEL,PREVIOUS_SELECTED_BLOGS,PREVIOUS_SELECTED_TAGS,PREVIOUS_SELECTED_TAGS_VALUES } from '../utilities';

/**
 * To get blog list
 * @param {*} data 
 */
export function fetchBlogs(dataObj = {}) {
    return (dispatch, getState) => {
        const requestOptions = getData();
        let url = constand.BACKEND_URL + '/api/blog?cat='+dataObj.condition+'&search='+dataObj.search+'&limit='+dataObj.limit+'&offset='+dataObj.offset;
        if(dataObj.type) {
            url += '&type='+dataObj.type;
        }
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response, dispatch))
            .then(response => {
                return response;
            }).catch((e)=>{
          
            })
    }
}

/**
 * To get recent blog list
 * @param {*} data 
 */
export function fetchRecentBlogs(tag) {
    return (dispatch, getState) => {
        const requestOptions = getData();
        let url = constand.BACKEND_URL + '/api/blog/about/recent/blogs?cat=' + tag;
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response, dispatch))
            .then(response => {
                return response;
            }).catch((e)=>{

            })
    }
}

/**
 * To get blog detail
 * @param {*} data 
 */
export function fetchBlogDetail(blogName) {
    return (dispatch, getState) => {
        const requestOptions = getData();
        let url = constand.BACKEND_URL + '/api/blog/' + blogName;
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response, dispatch))
            .then(response => {
                return response;
            })
    }
}

/**
 * To save and unsave blog
 * @param {*} data 
 */
export function saveBlog(dataObj, flag) {
    return (dispatch, getState) => {
        const requestOptions = postData(dataObj);
        let url;
        if (flag)
            url = constand.BACKEND_URL + '/api/blog/unSave';
        else
            url = constand.BACKEND_URL + '/api/blog/addSave';
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response, dispatch))
            .then(response => {
                return response;
            })
    }
}

/**
 * To get all blog title
 * @param {*} data 
 */
export function getBlogsTitle() {
    return (dispatch, getState) => {
        const requestOptions = getData();
        let url = constand.BACKEND_URL + '/api/blog/blogs/blogTitle';
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response, dispatch))
            .then(response => {
                dispatch({
                    type: GET_BLOG_TITLES,
                    payload: response.data.length > 0 ? response.data : []
                });
                return response;
            }).catch((e)=>{

            })
    }
}

export function conditionBlogModel(arg, isBlog=false) {
    return (dispatch, getState) => {
      dispatch({
        type: isBlog ? WELCOME_CONDITION_MODEL_BY_BLOG : WELCOME_CONDITION_MODEL,
        payload: arg
      });
    }
  }

  export function previousBlogState(arg) {
    return (dispatch, getState) => {
      dispatch({
        type: PREVIOUS_SELECTED_BLOGS,
        payload: arg
      });
    }
  }

  export function previousTagsState(arg) {
    return (dispatch, getState) => {
      dispatch({
        type: PREVIOUS_SELECTED_TAGS,
        payload: arg
      });
    }
  }

  export function previousSelectedTags(arg) {
    return (dispatch, getState) => {
      dispatch({
        type: PREVIOUS_SELECTED_TAGS_VALUES,
        payload: arg
      });
    }
  }
