import React from "react";
import { connect } from "react-redux";
import moment from 'moment-timezone';
import { fetchDashboardSchedule, start_loader, stop_loader, loginModelOpen, joinClass, cancelClass } from "../../actions";
import { commonService } from "../../_services";
import AddToCalendar from 'react-add-to-calendar';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

class EventCalendarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: {
                title: '',
                description: 'Friendly reminder: you scheduled some feel-good \'you\' time on Beam. Are you ready to get moving? You can do it! ',
                startDate: '',
                endTime: '',
                startTime: '',
                timeZone: '',
            }
        };
    }
    componentDidMount() {
        const item = this.props.item;
        const temp = { ...this.state.event };
        const titleText = (this.props.type !== "Live") 
            ? "Beam Class: " 
            : "Live Beam Class: ";
        temp.title = titleText + this.props.title;
        temp.description = this.state.event.description + window.location;

        // Get local timezone
        const localTimeZone = moment.tz.guess();

        // Convert scheduled time to the local timezone
        const scheduledFor = moment.tz(item.scheduledFor, localTimeZone);

        // Update event details
        temp.startDate = scheduledFor.format("YYYY-MM-DD");
        temp.startTime = scheduledFor.format("HH:mm");
        temp.endTime = scheduledFor
            .clone()
            .add(this.props.length, 'minutes')
            .format("HH:mm");
        temp.timeZone = localTimeZone
        this.setState({ event: temp });
    }

    render() {
        const { event } = this.state;
        if (!event.title || !event.startDate) {
            return <div>Loading...</div>; // Show a fallback UI while data is being prepared
        }

        return (
            <div className="d-flex justify-content-center w-100">
                <AddToCalendarButton
                    name={event.title}
                    description={event.description}
                    startDate={event.startDate}
                    startTime={event.startTime}
                    endTime={event.endTime}
                    options={['Apple', 'Google', 'Yahoo', 'iCal']}
                    timeZone={event.localTimeZone} // Passing the local timezone
                    buttonStyle="text"
                    trigger="click"
                    hideBackground
                    hideIconModal
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
    };
};

const mapDispatchToProps = {
    fetchDashboardSchedule, start_loader, stop_loader, loginModelOpen, joinClass, cancelClass
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventCalendarComponent);
