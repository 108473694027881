import React from 'react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { loginModelOpen, facebookLogin, googleLogin, isAuth, start_loader, stop_loader, assignFacebookValues, assignGoogleValues, fetchUserDetails, fetchUserOtherDetails } from "../../actions";
import * as constand from "../../constant";
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
class SignupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.facebookResponse = this.facebookResponse.bind(this);
  }
  componentDidMount() {
  }

  facebookResponse = (e) => {
    console.log('facebookres', e)
    this.props.facebookLogin(e, 'register').then(
      response => {//If your already singed up
        console.log(JSON.stringify(response));
        this.closeModel();
        this.getUserDetail();
        return;
      },
      error => {
      }
    );
    this.props.nextStep();
    this.props.assignFacebookValues(e);
  };

  onCloseModal() { }

  getUserDetail() {
    this.props.start_loader();
    this.props.fetchUserDetails().then(
      response => {
        this.props.fetchUserOtherDetails()
        this.props.stop_loader();
        const { from } = { from: { pathname: "/home" } };
        this.props.history.push(from);
        return;
      },
      error => {
        this.props.stop_loader();
        toast.error(error);
      }
    );
  }

  closeModel() //for close the login model
  {
    this.props.loginModelOpen(false);
  }

  handleClick = () => {
    window.location.href = "https://www.kidneybeam.com";
  };

  render() {
    return (
      <div className="first-section">
        <h3
          className=" text-center"
          id="exampleModalLabel font-medium"
        >
          How would you like to sign up ? <Link
            to="/home"
            className="close-register orangefont"
          >
            X
          </Link>
        </h3>
        
        {!this.props.invitationCode && <>
          <div className="col-8 offset-2 btnht_set">
            <div className="row justify-content-center">
              <img src={constand.S3_WEB_IMGES + "/Kidney-Beam-Logo.png"} alt='' className="col-md-3 m-b-20 p-l-0 " />
              <p className="text-center black-txt font-16 font-medium">
                Looking to register for classes for people with kidney disease? Kidney Beam has now moved to a new, dedicated web address at <a href="https://www.kidneybeam.com" rel="noopener noreferrer">
                  www.kidneybeam.com</a>.</p>
              <button className="btn font-18 m-t-20 m-b-20 btn-takeme col-md-9" onClick={this.handleClick} >
                Take me to Kidney Beam
              </button>
            </div>
          </div>
          <div className="text-center font-16 font-medium m-t-10 m-b-10 grey-txt">Otherwise...</div>

        </>}
        <div className="row justify-content-center align-items-center">

          <div className="input_section col-md-6 m-t-30">
            <div className="form-group">
              <p className="text-center">
                {/* <FacebookLogin
                        appId={constand.FACEBOOK_APP_KEY}
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={this.facebookResponse}
                        cssclassName="google btn btn-lg btn-facebook font-semibold font-24 mybtn"
                        icon="fa fa-facebook-square"
                        textButton="Continoue with Facebook"
                        /> */}
                <div>
                  <GoogleOAuthProvider clientId={constand.GOOGLE_CLIENT_ID}>
                    <GoogleLoginComponent onGoogleLogin={this.props.googleLogin} onNextStep={this.props.nextStep} onAssignGoogleValues={this.props.assignGoogleValues} onCloseModal={this.closeModel.bind(this)} onGetUserDetail={this.getUserDetail.bind(this)} />
                  </GoogleOAuthProvider>
                </div>

                <div className="text-center font-18 font-qregular m-t-10 m-b-10 black-txt"><b>or</b></div>

                <FacebookLogin
                  version='19.0'
                  appId={constand.FACEBOOK_APP_KEY}
                  autoLoad={false}
                  disableMobileRedirect={true}
                  fields="first_name,last_name,email,picture"
                  callback={this.facebookResponse}
                  render={renderProps => (
                    <button onClick={renderProps.onClick} className="  btn font-semibold font-24 mybtn btn-facebook"><i className="fa fa-facebook-official" aria-hidden="true"></i><span className="signup-text">Continue with Facebook</span></button>
                  )}
                />
              </p>
            </div>

            <div className="text-center font-18 font-qregular m-t-10 m-b-10 black-txt"><b>or</b></div>

            <div className="form-group">
              <p className="text-center">
                <button onClick={() => { this.props.nextStep() }} className="  btn font-semibold font-24 mybtn btn-login m-b-30">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i> <span className="signup-text">Signup with email</span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function GoogleLoginComponent(props) {
  const onSuccess = async (successResponse) => {
    const googleUser = await axios
      .get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${successResponse.access_token}` },
      })
      .then(res => res.data);

    props.onGoogleLogin(googleUser, 'register').then(
      response => {
        console.log(JSON.stringify(response));
        props.onCloseModal();
        props.onGetUserDetail();
        return;
      },
    ).catch((err) => {
      props.onNextStep();
    });

    props.onAssignGoogleValues(googleUser);
  }

  const login = useGoogleLogin({
    onSuccess: onSuccess
  });

  return <button onClick={login} className="btn font-semibold font-24 mybtn btn-google"><i className="fa fa-google" aria-hidden="true"></i><span className="signup-text">Signup with Google</span></button>
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = { isAuth, facebookLogin, googleLogin, loginModelOpen, assignFacebookValues, assignGoogleValues, fetchUserDetails, start_loader, stop_loader, fetchUserOtherDetails };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupComponent);
