import {
    GET_BLOG_TITLES,WELCOME_CONDITION_MODEL_BY_BLOG,PREVIOUS_SELECTED_BLOGS,PREVIOUS_SELECTED_TAGS,PREVIOUS_SELECTED_TAGS_VALUES
} from '../utilities';
import _ from 'lodash';

const INITIAL_STATE = {
    blog_titles:[],
    is_condition_model_open_by_blog:false,
    selected_blog_category_array: [],
    selected_tags_array: [],
    previous_selected_tags:'',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_BLOG_TITLES:
            console.log('blogs-action.payload',action.payload)
            return {
                ...state,
                blog_titles: action.payload
            };
        case WELCOME_CONDITION_MODEL_BY_BLOG:
                return {
                    ...state,
                    is_condition_model_open_by_blog: action.payload,
                    pageUrl:'resources/all'
                }
        case PREVIOUS_SELECTED_BLOGS:
            return {
                ...state,
                selected_blog_category_array:action.payload,
            }
        case   PREVIOUS_SELECTED_TAGS:
            return {
                ...state,
                selected_tags_array:action.payload,
            }
        case   PREVIOUS_SELECTED_TAGS_VALUES:
            return {
                ...state,
                previous_selected_tags:action.payload,
            }
        default:
            return state;
    }
};
