import React from 'react';
import { connect } from "react-redux";
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import { getDynamicPage } from "../../actions"
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import { RichText } from 'prismic-reactjs';
import { ImageTag, TextWrap } from "../../tags";
import { Link } from "react-router-dom";
const _ = require('lodash');

class DynamicPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicPageDetails: {},
      Loading: false,
      horizontal_section: [],
      isMapPage: false,
      isDynamicPage: false,
      isLandingPage: false
    };
  }
  componentDidMount() {
    this.getDynamicPage();
    window.scrollTo(0, 0)
  }

  getDynamicPage = () => {
    this.setState({ Loading: true })
    let param = this.props.params.condition;
    this.props.getDynamicPage(param).then(response => {
      this.setState({ dynamicPageDetails: {} })
      if (response) {
        let dynamicPageDetails = response.data;
        if (dynamicPageDetails && dynamicPageDetails.length > 0 && dynamicPageDetails[0].tags.includes('map')) {
          this.setState({ isMapPage: true, isDynamicPage: false, isLandingPage: false })
        } else if (dynamicPageDetails && dynamicPageDetails.length > 0 && dynamicPageDetails[0].tags.includes('landing-page')) {
          this.setState({ isMapPage: false, isDynamicPage: true, isLandingPage: true })
        } else {
          this.setState({ isMapPage: false, isDynamicPage: true, isLandingPage: false })
        }
        let horizontal_section = dynamicPageDetails[0].data && dynamicPageDetails[0].data.horizontal_section
        horizontal_section = horizontal_section && horizontal_section.sort((a, b) => parseFloat(a.order_on_page) - parseFloat(b.order_on_page));
        this.setState({
          dynamicPageDetails: dynamicPageDetails[0].data,
          horizontal_section: horizontal_section,
          Loading: false
        });
      }
    }, error => {
      console.log(error);
    });
  }

  imageSection(item, key) {
    let image = (item && item.horizontal_section_image) ? item.horizontal_section_image.url : '';
    return (
      <figure className="position-relative image-text-blog image-text-blognew text-center">
        <ImageTag className="img-fluid" src={image} />
      </figure>);
  }
  bodyTextSection(item, isLanding = false) {
    let data = (item.items && item.items.length > 0) ? item.items[0].general_page_text : '';
    if (data && data.length > 0) {
      let renderHtml = isLanding ? <> {RichText.asText(data)} </> : <p class="w-80 mx-auto p-t-20 font-21 p-b-10 font-medium">;
        {RichText.asText(data)}
      </p>
      return renderHtml;
    }
  }
  titleSection(item) {
    let data = (item && item.horizontal_section_title.length > 0) ? item.horizontal_section_title : '';
    let renderHtml = <>{RichText.asText(data)}</>;
    return renderHtml;
  }
  textSection(item) {
    let data = (item && item.horizontal_section_text.length > 0) ? item.horizontal_section_text : '';
    let renderHtml = <><RichText render={data} /></>;
    return renderHtml;
  }
  colourSelection(item) {
    let data = (item && item.horizontal_section_colour.length > 0) ? item.horizontal_section_colour : '';
    let renderHtml = data;
    return renderHtml;
  }
  colourText(item) {
    let data = (item && item.horizontal_section_text_colour) ? item.horizontal_section_text_colour : '';
    let renderHtml = data;
    return renderHtml;
  }
  sectionAlignment(item) {
    let data = (item && item.horizontal_section_alignment.length > 0) ? item.horizontal_section_alignment : '';
    let renderHtml = data;
    return renderHtml;
  }

  videoSection(item) {
    let video = (item && item.horizontal_section_video) ? item.horizontal_section_video.html : '';
    let renderHtml = <div class="text-center justify-content-center h-100" dangerouslySetInnerHTML={{ __html: video }} />;
    return renderHtml;
  }

  htmlSection(item) {
    let header_html_code = (item.items && item.items.length > 0) ? item.items[0].header_html_code : [];
    let content_html_code = (item.items && item.items.length > 0) ? item.items[0].content_html_code : [];
    let renderHtml = <div><div dangerouslySetInnerHTML={{ __html: RichText.asText(header_html_code) }} /><div dangerouslySetInnerHTML={{ __html: RichText.asText(content_html_code) }} /></div>;
    return renderHtml;
  }

  buttonTextSection(item) {
    let data = (item && item.button_text.length > 0) ? item.button_text : '';
    let renderHtml = <>{RichText.asText(data)}</>;
    return renderHtml;
  }

  buttonTextColor(item) {
    let data = (item && item.button_text_colour) ? item.button_text_colour : '';
    let renderHtml = data;
    return renderHtml;
  }

  buttonBackgroundColor(item) {
    let data = (item && item.button_background_colour) ? item.button_background_colour : '';
    let renderHtml = data;
    return renderHtml;
  }
  goToButtonUrl = (item) => {
    let data = (item && item.button_url.length > 0) ? item.button_url : '';
    let returnData = data.length > 0 ? data[0].text : '#';
    return returnData
  }
  buttonAlignment = (item) => {
    let data = (item && item.button_alignment) ? item.button_alignment : '';
    if (data == "centre") {
      data = "center"
    }
    return data
  }

  buttonTextFormat = (item) => {
    let data = (item && item.button_text_format) ? item.button_text_format : '';
    return data
  }

  buttonLocation = (item) => {
    let data = (item && item.button_location) ? item.button_location : '';
    return data
  }

  /* buttonSection = (item) => {
    let data = (item && item.button_section) ? item.button_section : '';
    return data
  } */
  renderButton = (item) => {
    return (
      <>
        <div style={{
          textAlign: item.button_alignment && this.buttonAlignment(item)
        }}>
          <button className="dynamic_button font-medium" style={{
            backgroundColor: item.button_background_colour && this.buttonBackgroundColor(item),
            fontStyle: item.button_text_format && this.buttonTextFormat(item),
            fontWeight: item.button_text_format && this.buttonTextFormat(item),
            textDecoration: item.button_text_format && this.buttonTextFormat(item)
          }} >
            <a className="dynamic_link" style={{ color: item.button_text_colour && this.buttonTextColor(item) }} href={this.goToButtonUrl(item)} > {this.buttonTextSection(item)}</a>
          </button>

        </div>
      </>
    )
  }

  renderDynamicPage = () => {
    return (
      <div>
        {/* normal dynamic page banner */}
        {!this.state.isLandingPage && <section className="homecontent h-auto ">
          <div className="section1-bg teenonbeams_sec">
            <div class="container-fluid mx-auto w-75">
              <div class="section-header text-center p-t-30 p-b-30">
                <h3 class="darkblue-text font-37 font-medium"><RichText className="black-txt font-qregular m-b-10" render={this.state.dynamicPageDetails['title']} /></h3>
                {this.state.dynamicPageDetails.body && this.state.dynamicPageDetails.body.map((item, key) => {
                  return (<React.Fragment key={key}>
                    {item.items.length > 0 && (item.slice_type === 'textsection') && this.bodyTextSection(item)}
                  </React.Fragment>);
                })}
              </div>
            </div>
          </div>
        </section>}
        {/* landing dynamic page banner */}
        {this.state.isLandingPage && <div className="kd_landing_page">
          <section className="kd_landing_page bg-contrast1">
            <div class="container-fluid w-80 fullwidth">
              <div class="row">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 text-center welcome-container mx-auto minheight_banner vert_center_banner">
                  <div className="welcome-kidney font-bold white-txt">
                    {RichText.asText(this.state.dynamicPageDetails['title'])}
                  </div>
                  {/* <div class="helping-physiotherap white-txt font-qbold"> */}
                  {this.state.dynamicPageDetails.body && this.state.dynamicPageDetails.body.map((item, key) => {
                    return (<React.Fragment key={key}>
                      {item.items.length > 0 && (item.slice_type === 'textsection') && this.bodyTextSection(item, true)}
                    </React.Fragment>);
                  })}
                  {/* </div> */}
                </div>
                <div class="col-xl-3 col-lg-2 col-md-3 col-sm-3 d-sm-block header-img">
                  <img class="img-fluid" src={constand.WEB_IMAGES + "Sun-landing.png"} />
                </div>
              </div>
            </div>
          </section>
        </div>
        }
        <section className="section1-bg teenonbeams_sec">

          {/* horizontal_section start */}
          <div>
            {this.state.horizontal_section && this.state.horizontal_section.map((item, key) => {
              return (<React.Fragment key={key}>
                <div className="row" style={{ backgroundColor: item.horizontal_section_colour && this.colourSelection(item) }}>
                  <div className="container-fluid mx-auto w-75 video_height">                  
                    <div className="row p-t-60 p-b-30">
                      {/* Button whole above section */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                      {((Object.keys(item.horizontal_section_image).length !== 0) || (Object.keys(item.horizontal_section_video).length !== 0)) && (Object.keys(item.horizontal_section_text).length !== 0) && <>
                      {/* button section */}
                      {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == "whole_section" && <div className="text-center p-b-20">{this.renderButton(item)}</div>}
                      </>
                      }                     
                      </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                      <div className="row p-0">
                      {/* image section */}
                      {(Object.keys(item.horizontal_section_image).length !== 0) && (Object.keys(item.horizontal_section_text).length !== 0) && 
                        <>
                          <div className={'col-12 col-md-6 col-lg-6 text-center onlyimgsec float' + item.image_align}>
                            {/* button section */}
                            {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == 'under_image' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}
                            {item.horizontal_section_image && this.imageSection(item, key)}
                            {/* button section */}
                            {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == 'under_image' && <div className="p-b-10 p-t-30">{this.renderButton(item)}</div>
                            }

                          </div>
                        </>
                      }

                      {/* video section */}
                      {(Object.keys(item.horizontal_section_video).length !== 0) &&
                        <>
                          <div className={'col-12 col-md-6 col-lg-6 text-center video_bottom_mb float' + item.image_align}>
                            {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == 'under_image' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>
                            }
                            {(item.horizontal_section_video.type == "video") && this.videoSection(item)}
                            {/* button section */}
                            {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == 'under_image' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>
                            }
                          </div>

                        </>
                      }

                      {/* video content section */}
                      {(Object.keys(item.horizontal_section_video).length !== 0) &&
                        <div className={'col-12 col-md-6 col-lg-6 text-left float' + item.text_align}>
                          {/* Title section */}
                          <h4 style={{ color: this.colourText(item, key) }} className="font-24 font-normal font-semibold font-medium">
                            {item.horizontal_section_title && this.titleSection(item)}
                          </h4>
                          {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == "under_text" && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}
                          {/* text selection */}
                          <div className="black-txt font-qregular m-b-10"
                            style={{
                              color: item.horizontal_section_text_colour &&
                                this.colourText(item)
                            }}
                          >
                            {item.horizontal_section_text && this.textSection(item)}
                          </div>
                          {/* button section */}
                          {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == "under_text" && <div className="p-t-30">{this.renderButton(item)}</div>}

                        </div>
                      }

                      {/* image content section */}
                      {(Object.keys(item.horizontal_section_image).length !== 0) &&
                        <div className={'col-12 col-md-6 col-lg-6 text-left float' + item.text_align}>

                          {/* Title section */}
                          <h4 style={{ color: this.colourText(item, key) }} className="font-24 font-normal font-semibold font-medium">
                            {item.horizontal_section_title && this.titleSection(item)}
                          </h4>
                          {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == "under_text" && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}

                          {/* text selection */}
                          <div className="black-txt font-qregular m-b-10"
                            style={{
                              color: item.horizontal_section_text_colour &&
                                this.colourText(item),
                            }}
                          >
                            {item.horizontal_section_text && this.textSection(item)}
                          </div>
                          {/* button section */}
                          {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == "under_text" && <div className="p-t-30">{this.renderButton(item)}</div>}
                        </div>
                      }
                      </div>
                      </div>
                      {/* Button whole below section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                      {((Object.keys(item.horizontal_section_image).length !== 0) || (Object.keys(item.horizontal_section_video).length !== 0)) && (Object.keys(item.horizontal_section_text).length !== 0) && 
                      <>
                      {/* button section */}
                      {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == "whole_section" && <div className="text-center p-t-20">{this.renderButton(item)}</div>}
                      </>
                      }                        
                      </div>

                      {/* if not image and video or text only section  */} 
                      {(Object.keys(item.horizontal_section_image).length === 0) && (Object.keys(item.horizontal_section_video).length === 0) &&
                        <div className="w-100 mx-auto">
                          {/* button whole section */}
                          {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == 'whole_section' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}

                          {/* Title section */}
                          <h4 style={{ textAlign: "left", color: this.colourText(item, key) }} className="font-24 font-normal font-semibold font-medium">
                            {item.horizontal_section_title && this.titleSection(item)}
                          </h4>

                          {/* button section */}
                          {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == 'under_text' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}

                          {/* text selection */}
                          <div className="black-txt font-qregular"
                            style={{
                              color: item.horizontal_section_text_colour &&
                                this.colourText(item), textAlign: "left"
                            }}
                          >
                            {item.horizontal_section_text && this.textSection(item)}
                          </div>

                            {/* button whole section */}
                            {item.button_needed == 'Yes' && item.button_location == "above" && item.button_section == 'under_text' && <div className="p-b-20 p-t-20">{this.renderButton(item)}</div>}

                          {/* button section */}
                          {item.button_needed == 'Yes' && item.button_location == "below" && item.button_section == 'whole_section' && <div className="p-t-30">{this.renderButton(item)}</div>}
                        </div>
                      }
                      {/* Only image section */}
                      {(Object.keys(item.horizontal_section_text).length === 0) && (Object.keys(item.horizontal_section_title).length === 0) && (Object.keys(item.horizontal_section_video).length === 0) && (Object.keys(item.horizontal_section_image).length > 0) && 
                        <div className="w-75 mx-auto text-center">
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            {
                              item.button_section == "whole_section" && item.button_location == "above" && item.button_needed == 'Yes' &&
                              <div className='p-t-30'>{this.renderButton(item)}</div>
                            }
                          </div>
                          {item.horizontal_section_image && this.imageSection(item, key)}
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            {
                              item.button_section == "whole_section" && item.button_location == "below" && item.button_needed == 'Yes' &&
                              <div className='p-t-30'>{this.renderButton(item)}</div>
                            }
                          </div>
                        </div>

                      }

                      {/* Only video section */}
                      {(Object.keys(item.horizontal_section_text).length === 0) && (Object.keys(item.horizontal_section_title).length === 0) && (Object.keys(item.horizontal_section_image).length === 0) && Object.keys(item.horizontal_section_video).length > 0 &&
                        <div className="w-75 mx-auto text-center">
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            {
                              item.button_section == "whole_section" && item.button_location == "above" && item.button_needed == 'Yes' &&
                              <div className='p-t-30'>{this.renderButton(item)}</div>
                            }
                          </div>
                          {item.horizontal_section_video.type == "video" && this.videoSection(item, key)}
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            {
                              item.button_section == "whole_section" && item.button_location == "below" && item.button_needed == 'Yes' &&
                              <div className='p-t-30'>{this.renderButton(item)}</div>
                            }
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </React.Fragment>);
            })}
          </div>
          {/* horizontal_section end */}
        </section>
      </div>
    )
  }

  renderMapPage = () => {
    return (
      <section className="homecontent h-auto ">
        <div className="section1-bg teenonbeams_sec">
          <div class="container-fluid mx-auto w-75">
            <div class="section-header text-center p-t-30 p-b-30">
              <h3 class="darkblue-text font-37 font-medium"><RichText className="black-txt font-qregular m-b-10" render={this.state.dynamicPageDetails['title']} /></h3>
              {this.state.dynamicPageDetails.body && this.state.dynamicPageDetails.body.map((item, key) => {
                return (<React.Fragment key={key}>
                  {item.items.length > 0 && (item.slice_type === 'textsection') && this.bodyTextSection(item)}
                  {item.items.length > 0 && (item.slice_type === 'htmlsection') && this.htmlSection(item)}
                </React.Fragment>);
              })}
            </div>
          </div>
        </div>
      </section>
    )
  }
  render() {
    return (
      <>
        {/* dynamic detail page */}
        {this.state.isDynamicPage && this.renderDynamicPage()}
        {/* map page detail */}
        {this.state.isMapPage && this.renderMapPage()}
        <div className="text-center w-100">
          {(this.state.Loading) && (<AnimateLoaderComponent />)}
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    is_auth: state.auth.is_auth,
  };
};

const mapDispatchToProps = {
  getDynamicPage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicPage);
