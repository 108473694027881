import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as constand from "../../constant";
import { commonService } from "../../_services";
import { toast } from "react-toastify";
import { fetchClassList, start_loader, stop_loader, joinClass, loginModelOpen, cancelClass, getInstructor, applyLiveclassFilter } from "../../actions";
import moment from 'moment';
import PastLiveClasses from "./PastLiveClasses";
import CalendarViewComponent from "../Common/CalendarViewComponent";
import ListViewComponent from "../Common/ListViewComponent";
import AnimateLoaderComponent from "../../_layoutComponents/AnimateLoaderComponent";
import JoinClassComponent from "./LiveClassJoinModel";
import { ImageTag } from "../../tags";
import { Helmet } from "react-helmet";
import { Cookies } from "react-cookie-consent";
import UnscheduledLiveClasses from "./UnscheduledLiveClasses";
import ResearchStudyPollPopup from "../WorkoutDetailPage/ResearchStudyPollPopup";
import _ from 'lodash';
import AdminBanner from "../Common/AdminBanner";

class LiveClassesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: true,
            signupLoader: false,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            liveClassList: [],
            allLiveClassData: [],
            displayLayout: true,
            type: this.props.params.condition,
            modelState: false,
            modelData: {},
            firstScheduleDate: '',
            total_tags: [],
            total_levels: [],
            is_toggled: false,
            classType: 'upcoming',
            instructorOptions: [],
            selected_conditionid: '',
            selectedValue: '',
            openResearchModel: false,
            current_attendee: {},
            redirectUrl: '',
            workoutId: '',
            showBanner: false
        };
        this.fetchLiveClassList = this.fetchLiveClassList.bind(this);
        this.submitStartNow = this.submitStartNow.bind(this);
        this.modelClose = this.modelClose.bind(this);
        this.joinClassService = this.joinClassService.bind(this);
        this.toggleClasses = this.toggleClasses.bind(this);
        this.getInstructor = this.getInstructor.bind(this);
        this.closeResearchModel = this.closeResearchModel.bind(this);
        this.viewPrepoll = this.viewPrepoll.bind(this);
        this.beforeReview = this.beforeReview.bind(this);
        this.lastState = this.props.params.condition;
    }
    /** fetch live class list on page did mount */
    componentWillMount() {
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;
        if (authData && (authData.isGroupLeader || authData.isStudyLeader) && this.props.is_create_mode) {
            this.setState({ classType: 'unscheduled' })
        }
    }
    componentDidMount() {
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

        if (authData && (authData.isGroupLeader || authData.isStudyLeader) && this.props.is_create_mode) {
            this.toggleClasses(true);
            this.setState({ classType: 'unscheduled' })
        }
        if (!this.props.logged_userData.isStudyLeader && !this.props.logged_userData.isGroupLeader && !this.props.logged_userData.isStudyUser && !this.props.logged_userData.isStudyInstructor) {
            this.setState({ showBanner: true })
        }
        if (typeof Cookies.get('is_toggled') == 'undefined') {
            if (Cookies.get('CookieControl') && Object.keys(JSON.parse(Cookies.get('CookieControl')).optionalCookies).length > 0 && JSON.parse(Cookies.get('CookieControl')).optionalCookies.preferences == 'accepted')
                Cookies.set('is_toggled', false);
        }

        this.fetchLiveClassList();
    }

    submitStartNow(item) {
        if (this.props.is_auth) {
            this.joinClassService(item);
        } else {
            this.props.loginModelOpen(true);
        }
    }

    closeResearchModel() {
        this.setState({ openResearchModel: false });
    }

    beforeReview() {
        window.open(this.state.redirectUrl);
    }

    viewPrepoll(live) {
        live.Attendees.forEach((item) => {
            if (item.UserId === this.props.logged_userData.id) {
                this.setState({ redirectUrl: item.reference, current_attendee: item });
            }
        })
        this.setState({ openResearchModel: true, workoutId: live.WorkoutId })
    }

    /**
     * call after sign in
     */
    componentWillReceiveProps(nextProps) {
        let authData = JSON.parse(localStorage.getItem('userDetails')) || this.props.logged_userData;

        if (!this.props.is_auth && nextProps.is_auth) {
            this.setState({ liveClassList: [] });
            // this.fetchLiveClassList();
        }

        if (this.props.is_create_mode != nextProps.is_create_mode) {
            if (authData.isStudyLeader)
                this.setState({ classType: 'unscheduled' })
            else
                this.setState({ classType: 'upcoming' })
        }
        console.log('liveclass-laststate', nextProps)
        if (this.lastState != nextProps.params.condition) {
            this.lastState = nextProps.params.condition;
            this.setState(
                {
                    type: nextProps.params.condition
                },
                function () {
                    //this.componentDidMount()

                }
            );
        }
    }

    /**
     *
     * @param {*} item
     */
    joinClassService(item) {
        var dataObj = { "roomId": item.id };
        this.setState({ signupLoader: true });
        //this.props.start_loader();
        this.props.joinClass(dataObj).then(
            response => {
                //this.props.stop_loader();
                if (response) {
                    this.setState({
                        signupLoader: false,
                        modelState: true,
                        modelData: item
                    });
                }
            },
            error => {
                //this.props.stop_loader();
                this.setState({
                    signupLoader: false,
                    modelState: false,
                    modelData: {}
                });
                toast.error(error);
            }
        );
    }

    modelClose() {
        this.setState({
            Loading: false,
            offset: 0,
            count: 0,
            show_more_cnt: constand.CLASS_LIST_CNT,
            liveClassList: [],
            displayLayout: true,
            type: this.props.params.condition,
            modelState: false,
            modelData: {}
        }, function () { this.fetchLiveClassList(); });
    }

    /**
     * fetch class list data
     */
    fetchLiveClassList() {

        this.setState({ Loading: true });
        var dataObj = {
            "offset": this.state.offset,
            "limit": constand.CLASS_LIST_CNT,
            "condition": commonService.replaceChar(this.state.type, true)
        };

        this.props.fetchClassList(dataObj).then(
            response => {
                var selected_conditionid = _.result(_.find(this.props.logged_userData.UserConditions, function (obj) {
                    return obj.Tag.tag.indexOf(commonService.replaceChar(Cookies.get('condition'), true)) != -1;
                }), 'conditionId');
                console.log('fetchLiveClassList-selected_conditionid', selected_conditionid)
                this.setState({ selected_conditionid: selected_conditionid });
                if (response) {
                    var list = response.list;
                    if (list && list.liveClasses) {
                        var liveClassData = list.liveClasses;
                        this.setState({ allLiveClassData: liveClassData });
                        this.toggleLiveClasses();
                    }
                    this.setState({
                        Loading: false,
                        count: list.count,
                        total_tags: (response.list.tags) ? response.list.tags : [],
                        total_levels: (response.list.levelTags) ? response.list.levelTags : []
                    }, () => {
                    });
                }
            },
            error => {
                this.setState({
                    Loading: false
                });
                this.props.stop_loader();
                //toast.error(error);
            }
        );
    }

    toggleClasses(toggled) {
        if (Object.keys(JSON.parse(Cookies.get('CookieControl')).optionalCookies).length > 0 && JSON.parse(Cookies.get('CookieControl')).optionalCookies.preferences == 'accepted')
            Cookies.set('is_toggled', toggled);
        console.log('is_toggeld', toggled)
        this.setState({ is_toggled: toggled, liveClassList: [] }, function () { this.toggleLiveClasses(); });
    }
    toggleLiveClasses() {
        var liveClassData = this.state.allLiveClassData;
        var is_toggled = Cookies.get('is_toggled'); //this.state.is_toggled;

        //if (is_toggled === 'true') {
        if (((typeof Cookies.get('is_toggled') == 'undefined' && this.state.is_toggled) || Cookies.get('is_toggled') == 'true')) {
            //show all
            this.groupListResult(liveClassData);

        } else {
            //waking hrs only
            var exceptArray = []
            liveClassData.map(function (liveclass, key) {
                var schedule = moment(liveclass.scheduledFor).toDate();
                //var midnight12 = moment(moment(liveclass.scheduledFor).startOf('day')).toDate();
                var midnight12 = moment(moment(liveclass.scheduledFor).hour('0').minute('01').second('0')).toDate();
                var morninig5 = moment(moment(liveclass.scheduledFor).hour('4').minute('59').second('0')).toDate();

                if (!(schedule >= midnight12 && schedule <= morninig5)) {
                    console.log('schedule ' + schedule + ' midnight12 ' + midnight12 + ' morninig5 ' + morninig5)
                    console.log(liveclass.id + '************NOT MID NIGHT*****************');
                    exceptArray.push(liveclass)
                }
            });
            this.groupListResult(exceptArray);
        }
    }
    /**
   * render discipline list
   */
    renderDisciplineList(item, label) {
        var getDiscipline = commonService.returnTag("discipline", this.state.total_tags, item.WorkoutId);
        if (getDiscipline === 'None') {
            return (
                <div className={label === 'desktop' ? "col-md-12 col-lg-12 col-sm-6 small-device float-left p-0" : "col-12 float-left p-0"} ></div>
            )
        } else {
            return (
                <div className={label === 'desktop' ? "col-md-12 col-lg-12 col-sm-6 small-device float-left p-0" : "col-12 float-left p-0"}>
                    <span className="w-40 font-16 font-semibold black-txt float-left">
                        Discipline:
                    </span>
                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5">
                        <span className="orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                            {getDiscipline}
                        </span>
                    </span>
                </div>
            )
        }
    }
    /**
     * renderDificulty
     */
    renderDificulty(item, label) {
        var getDifficulty = commonService.returnTag("level", this.state.total_levels, item.WorkoutId);
        if (getDifficulty !== 'None') {
            return (
                <div className={label === 'desktop' ? "col-md-12 col-lg-12 col-sm-6 small-device float-left p-0" : "col-12 float-left p-0"}>
                    <span className="w-40 font-16 font-semibold black-txt float-left">
                        Difficulty:
                    </span>
                    <span className="font-16 orangefont w-60 float-left font-medium p-l-5 capitalize_text">
                        {getDifficulty}
                    </span>
                </div>
            )
        } else {
            return (
                <div className={label === 'desktop' ? "col-md-12 col-lg-12 col-sm-6 small-device float-left p-0" : "col-12 float-left p-0"}></div>
            )
        }
    }
    /**
 * render start now or go to class
 */
    renderSchedule(item) {
        var currentDateTime = moment().format('YYYY-MM-DD hh:mm::ss');
        var scheduleTime = moment(item.scheduledFor).format('YYYY-MM-DD hh:mm::ss');
        // if (scheduleTime < currentDateTime) {
        //     return (
        //         <a
        //             className="btn-purple pad_list_btn clearfix poniter"
        //             href={item.reference}
        //         >
        //             {scheduleTime < currentDateTime ? 'Go To Class' : 'Sign up'}</a>
        //     );
        // } else {
        //     return (
        //         <a onClick={() => this.submitStartNow(item)}
        //             className="btn btn-purple pad_list_btn clearfix pointer"
        //         >
        //             {scheduleTime < currentDateTime ? 'Go To Class' : 'Sign up'}</a>
        //     );
        // }
        if (item.reference && (item.reference.toLowerCase() === 'coming soon')) {
            return (
                <a className="btn-purple-inverse pad_list_btn clearfix"> {item.reference} </a>
            );
        } else {
            var tagCondition = commonService.replaceChar(Cookies.get('condition'), true).toLowerCase();
            if (item.Signedup && this.props.logged_userData.isStudyParticipant && constand.RESEARCH_STUDY_LIST.includes(tagCondition)) {
                return (
                    <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={() => this.viewPrepoll(item)} > Go to class </a>
                );
            } else if ((item.Signedup && !this.props.logged_userData.isStudyParticipant) || (item.Signedup && this.props.logged_userData.isStudyParticipant && !constand.RESEARCH_STUDY_LIST.includes(tagCondition))) {
                return (
                    <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" href={item.Attendees[0].reference || '#'} target="_blank" > Go to class </a>
                    // <a className="btn btn-purple-inverse w-100 pad_list_btn font-medium m-b-10" onClick={()=> this.viewPrepoll(item)} > Go to class List view </a>                
                );
            }
            else {
                return (
                    <button disabled={this.state.signupLoader} onClick={() => this.submitStartNow(item)} className="btn btn-purple-inverse w-100 font-medium m-b-10 pad_list_btn" > Sign up </button>
                );
            }
        }
    }
    //cancel class
    cancelCalss(item, index, key) {
        if (!this.state.cancelLoading) {
            this.setState({ cancelLoading: true });
            var dataObj = {
                "roomId": item.id
            };
            this.props.cancelClass(dataObj).then(
                response => {
                    if (response) {
                        toast.success(response.message);
                    }

                    const newItems = [...this.state.liveClassList];
                    newItems[index][key].Signedup = false;

                    this.setState({
                        liveClassList: newItems,
                        cancelLoading: false
                    });
                },
                error => {
                    this.setState({
                        cancelLoading: false
                    });
                }
            );
        }
    }
    /**
     * render item list
     */
    renderSubItemList(data, key) {

        return data.map((item, index) => (
            <div className="list-group-item" key={index}>
                <div className="col-md-12 desktop-view-live">
                    <div className="row">
                        <div className="media col-md-3 col-lg-3 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.state.type + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>
                                    <ImageTag className="media-object image-size img-rounded img-fluid" src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>

                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-md-9 col-lg-9 p-0">
                            <div className="col-md-9 col-lg-9 col-sm-9 float-left pl-20">

                                <div className="col-md-12 col-lg-12 col-sm-6 small-device float-left m-b-10 p-0">
                                    <div className="p-0 border-0 float-left w-100">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.state.type + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>  {item.Workout.title}
                                            </Link>
                                        </div><div className="w-80">with
                                            {item.Workout.Instructor.hasProfile &&
                                                <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + this.props.params.condition} className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </Link>
                                            }
                                            {!item.Workout.Instructor.hasProfile &&
                                                <span className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </span>
                                            }
                                        </div>

                                    </div>
                                </div>
                                {this.renderDisciplineList(item, 'desktop')}
                                {this.renderDificulty(item, 'desktop')}
                            </div>
                            <div className="col-lg-3 float-left col-md-3 p-0 text-center">
                                {this.renderSchedule(item)}
                                {(item.Signedup) &&
                                    <a href="javascript:void(0)"
                                        className="btn btn-purple w-100 pad_list_btn font-medium m-b-10 joinclass-blue-btn" onClick={() => this.cancelCalss(item, key, index)}
                                    >
                                        Oops, I can't make it
                                    </a>}
                                <span
                                    className="btn btn-default-list-blue w-100 clearfix pad_list_btn"
                                >
                                    {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                                </span>
                                <span
                                    className="btn btn-default-list-orange w-100 clearfix pad_list_btn"
                                >
                                    Live Session
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mobile-view-live">
                    <div className="row">
                        <div className="media col-12 p-0">
                            <figure className="pull-left position-relative">
                                <Link to={"/liveClass/" + this.state.type + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}>
                                    <ImageTag
                                        className="media-object image-size img-rounded img-fluid"
                                        src={constand.WORKOUT_IMG_PATH + item.Workout.id + "-img.png"}
                                    />
                                </Link>
                                <div className="time-box">
                                    <i className="fa fa-clock-o w-100"></i>
                                    <div className="w-100">{item.Workout.length} mins</div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-12 p-0">
                            <div className="col-12 float-left p-0 ">
                                <div className="col-12  float-left p-0">
                                    <div className="p-0 border-0 float-left w-100 m-t-5 m-b-5">
                                        <img
                                            className="img-fluid rounded-circle w-20 m-r-20 pull-left"
                                            src={item.Workout && item.Workout.Instructor && item.Workout.Instructor.img ? constand.USER_IMAGE_PATH + item.Workout.Instructor.img : constand.WEB_IMAGES + 'no-image.png'}
                                            alt="" width="75" height="75"
                                        />
                                        <div className="font-24 font-medium orangefont m-b-5 w-80">
                                            <Link to={"/liveClass/" + this.state.type + "/" + item.Workout.id} onClick={() => { Cookies.set('workoutId', item.id) }}> {item.Workout.title}</Link>
                                        </div><div className="w-80">with
                                            {item.Workout.Instructor.hasProfile &&
                                                <Link to={"/instructor/" + item.Workout.Instructor.id + '/' + this.props.params.condition} className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </Link>
                                            }
                                            {!item.Workout.Instructor.hasProfile &&
                                                <span className="font-16 font-semibold black-txt p-l-5">
                                                    {item.Workout.Instructor.User.name} {item.Workout.Instructor.User.lastName}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="font-medium col-12 p-0 m-b-10 float-left ">
                                    {this.renderDisciplineList(item, 'mobile')}
                                    {this.renderDificulty(item, 'mobile')}
                                    {/* <div className="col-md-4 col-sm-4 float-left p-0">
                                    <span className="w-40 float-left font-13 font-qregular black-txt">
                                        Language:
                                        </span>
                                    <span className="w-60 float-left">
                                        {" "}
                                        <img
                                            className="img-fluid p-l-5 p-b-10"
                                            src="/images/flag.png"
                                            alt=""
                                        />
                                    </span>
                                </div> */}
                                </div>
                            </div>

                        </div>
                        <div className="col-12 float-left p-0 text-center">
                            {this.renderSchedule(item)}
                            {(item.Signedup) &&
                                <a href="javascript:void(0)"
                                    className="btn btn-purple w-100 pad_list_btn font-medium m-b-10 joinclass-blue-btn" onClick={() => this.cancelCalss(item, key, index)}
                                >
                                    Oops, I can't make it
                                </a>}
                            <span
                                className="w-100 btn btn-default-list-blue clearfix pad_list_btn"
                            >
                                {moment(item.scheduledFor).format("hh:mm A")} {" " + commonService.localTimeZone()}
                            </span>
                            <span
                                className="w-100 btn btn-default-list-orange clearfix pad_list_btn"
                            >
                                Live Session
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }
    /**
     * render live class list
     */
    renderLiveClassList() {

        return this.state.liveClassList.map((item, index) => (
            <div key={index}>
                <p className="section_header font-semibold">
                    {commonService.getDayNameFromDate(item[0].scheduledFor)}
                </p>
                {this.renderSubItemList(item, index)}
            </div>
        ));
    }
    /**
     * render load more option
     */
    renderLoadMore() {
        if (this.state.count > constand.CLASS_LIST_CNT) {
            return (
                <div className="col-md-12 col-lg-12 col-sm-12 text-center float-left">
                    <span
                        onClick={() => { this.incrementShowmoreCnt() }}
                        className="btn btn-orange m-t-40 m-b-40 font-book pointer"
                    >
                        Show more live classes
                    </span>
                </div>
            );
        }
    }

    /**
    * render list
    */
    renderListData() {
        console.log('classType', this.state.classType)
        return (
            <div >
                <div className="col-md-12 p-0">
                    <div className="row">
                        <div className={(this.props.is_create_mode ? 'col-md-3' : (this.props.logged_userData.isInstructorUser != 0 ? 'col-md-6' : 'col-md-3'))}>
                            <p className="d-flex align-items-center justify-content-center justify-content-sm-start">
                                <i className={this.state.displayLayout ? 'fa fa-calendar-o' : 'fa fa-list-ul'} aria-hidden="true"></i>{" "}
                                <span
                                    onClick={() => { this.changeViewLayout() }}
                                    className="orangefont font-medium pointer p-l-10 "
                                >
                                    <u>{this.state.displayLayout ? 'Switch to calendar view' : 'Switch to list view'}</u>
                                </span>
                            </p>
                        </div>
                        {!this.props.is_create_mode && !this.props.logged_userData.isInstructorUser &&
                            <div className="col-md-7 mb-2">
                            <p className="w-40 font-16 font-semibold lable-rad text-center">All class times shown in {" " + commonService.localTimeZoneName()} time</p>
                        </div>
                    }
                    {(this.props.is_create_mode || this.props.logged_userData.isInstructorUser != 0) &&
                        <div className="col-md-7 mb-2">
                            <p className="w-40 font-16 font-semibold lable-rad text-center">All class times shown in {" " + commonService.localTimeZoneName()} time</p>
                        </div>
                    }
                    
                    {!this.state.type.toLowerCase().includes('research') &&
                        <div className="col-md-2 text-right mb-2">
                            {((typeof Cookies.get('is_toggled') == 'undefined' && !this.state.is_toggled) || Cookies.get('is_toggled') == 'false') &&
                                <span
                                    className="btn btn-orange-inverse button-filter showall-lg font-medium font-14 pointer w-100"
                                    onClick={() => { this.toggleClasses(true) }}
                                >
                                    Show all
                                </span>
                            }
                            {((typeof Cookies.get('is_toggled') == 'undefined' && this.state.is_toggled) || Cookies.get('is_toggled') == 'true') &&
                                <span
                                    className="btn btn-orange pointer w-100"
                                    onClick={() => { this.toggleClasses(false) }}
                                >
                                    Show waking hours only
                                </span>
                            }
                            </div>
                        }
                        {(this.props.is_create_mode || this.props.logged_userData.isInstructorUser != 0) && this.props.is_auth &&
                            <div className="col-md-3 text-right mb-2">
                                <span
                                    className={"p-1 btn button-filter close-btn font-medium font-14 pointer w-100 " + (this.state.classType == 'upcoming' ? 'btn-beam-blue white-txt' : 'btn-beam-blue-inverse')}
                                    onClick={() => { this.setState({ classType: 'upcoming' }) }}
                                > Upcoming </span>
                            </div>
                        }
                        {(this.props.is_create_mode || this.props.logged_userData.isInstructorUser != 0) && this.props.is_auth &&
                            <div className="col-md-3 text-right mb-2">
                                <span
                                    className={"p-1 btn button-filter close-btn font-medium font-14 pointer w-100 " + (this.state.classType == 'past' ? 'btn-purple' : 'btn-purple-inverse')}
                                    onClick={() => { this.setState({ classType: 'past' }) }}
                                > Past </span>
                            </div>
                        }
                        {this.props.is_auth && this.props.is_create_mode &&
                            <div className="col-md-3 text-right mb-2">
                                <span
                                    className={"p-1 btn button-filter close-btn font-medium font-14 pointer w-100 " + (this.state.classType == 'unscheduled' ? 'btn-darkblue white-txt' : 'btn-darkblue-inverse')}
                                    onClick={() => { this.setState({ classType: 'unscheduled' }) }}
                                >  Templates </span>
                            </div>
                        }
                        {this.props.is_create_mode && this.props.logged_userData.isStudyLeader && this.state.selected_conditionid &&
                            <div className="col-md-12 text-right mb-2">
                                <span
                                    className={"col-md-2 pull-right btn button-filter close-btn font-medium font-14 pointer w-100 " + (!this.state.is_filter_clicked ? 'btn-purple-inverse' : 'btn-purple white-txt')}
                                    onClick={() => { this.getInstructor(); }}
                                > {this.state.is_filter_clicked ? 'Close' : 'Filter'} </span>
                            </div>
                        }
                        {this.state.instructorOptions.length > 0 && this.state.is_filter_clicked &&
                            <div className="col-md-12">
                                <div className="row">
                                    {this.state.instructorOptions && this.state.instructorOptions.map((instructorList, key) => {
                                        return (
                                            <div className="col-md-4" key={key} onClick={() => { this.setState({ selectedValue: instructorList.value }); this.props.applyLiveclassFilter(instructorList.value); }}>
                                                <p className={"flow-text pointer " + (instructorList.value == this.state.selectedValue ? "font-bold" : "")} >{instructorList.label}</p>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {/*  <div className="text-center w-100">
                    {(this.state.Loading) && (<AnimateLoaderComponent />)}
                </div> */}
                <React.Fragment>

                    {this.renderLiveClassComponent()}
                </React.Fragment>

            </div>
        );

    }
    /**
     * load more class list
     */
    incrementShowmoreCnt() {
        this.setState({
            offset: this.state.offset + constand.CLASS_LIST_CNT
        }, function () {
            this.fetchLiveClassList();
        });
    }
    /**
     * for pagination result grouping
     */
    groupListResult(originalList) {
        let classList = [];
        console.log('this.state.liveClassList', this.state.liveClassList.length)
        let existingData = this.state.liveClassList;
        let newData = originalList;
        var helper = {};
        let results = [];
        let firstScheduleDate = '';
        newData.map(function (item, key) {
            if (key === constand.CONSTZERO) {
                firstScheduleDate = item.scheduledFor;
            }
            if (existingData && existingData.length > 0 && newData && item && moment(existingData[existingData.length - 1][0].scheduledFor).format("DD/MM/YYYY") === moment(item.scheduledFor).format("DD/MM/YYYY")) {
                existingData[existingData.length - 1].push(item);
                classList = existingData;
            } else {
                var key = moment(item.scheduledFor).format("DD/MM/YYYY");
                if (!helper[key]) {
                    helper[key] = [];
                    helper[key].push(item)
                    results.push(helper[key]);
                }
                else {
                    helper[key].push(item)
                }
                classList = [...existingData, ...results];
            }
        });
        this.setState({ liveClassList: classList, firstScheduleDate: firstScheduleDate })
    }
    /**
     * renderLiveClassComponent
     */
    renderLiveClassComponent() {
        if (this.state.displayLayout) {
            return (
                <div>
                    {this.state.classType == 'upcoming' &&
                        <ListViewComponent props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.state.type} location={this.props.location} liveClassList={this.state.liveClassList} allLiveClassData={this.state.allLiveClassData} componentType='Liveclass' history={this.props.history}  />
                    }
                    {this.state.classType == 'past' &&
                        <PastLiveClasses props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.state.type} location={this.props.location} liveClassList={this.state.liveClassList} allLiveClassData={this.state.allLiveClassData} componentType='Liveclass' liveclass_filter_instructor={this.props.liveclass_filter_instructor} />
                    }
                    {this.props.is_auth && this.props.is_create_mode && this.state.classType == 'unscheduled' &&
                        <UnscheduledLiveClasses props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.state.type} location={this.props.location} liveClassList={this.state.liveClassList} allLiveClassData={this.state.allLiveClassData} componentType='Liveclass' />
                    }
                </div>
            );
            /* return (
                <React.Fragment>
                    {(this.state.liveClassList && this.state.liveClassList.length > 0) &&
                        <div id="products" className="row">

                            <div className="list-group w-100">
                                {this.renderLiveClassList()}
                            </div>
                        </div>}
                </React.Fragment>
            ); */
        } else {
            return <CalendarViewComponent props={this.props} firstScheduleDate={this.state.firstScheduleDate} params={this.state.type} location={this.props.location} liveClassList={this.state.liveClassList} allLiveClassData={this.state.allLiveClassData} history={this.props.history} />
        }
    }

    /**
     * change Layout
     */
    changeViewLayout() {
        var layout = this.state.displayLayout;
        if (layout)
            this.setState({ classType: 'upcoming' })

        this.setState({
            displayLayout: !layout
        })
    }
    getInstructor() {
        var clicked = !this.state.is_filter_clicked;
        this.setState({ is_filter_clicked: clicked });
        if (!clicked) {
            this.props.applyLiveclassFilter('')
            this.setState({ selectedValue: '' })
        }
        var selected_conditionid = _.result(_.find(this.props.logged_userData.UserConditions, function (obj) {
            return obj.Tag.tag.indexOf(commonService.replaceChar(Cookies.get('condition'), true)) != -1;
        }), 'conditionId');
        if (selected_conditionid && this.state.instructorOptions.length == 0) {
            //instructor options based on conditions chosen
            this.props.getInstructor(null, selected_conditionid).then(
                response => {
                    if (response) {
                        this.setState({ instructorOptions: response.data.instructors })
                    }
                }, error => {
                    this.setState({
                        instructorOptions: []
                    });
                }
            )
        }
    }

    //main render
    render() {
        console.log('logged_userdata', this.props.logged_userData)
        return (
            <div
                className=""
            >
                <ResearchStudyPollPopup
                    is_model_open={this.state.openResearchModel}
                    closeResearchModel={this.closeResearchModel}
                    classType="Live"
                    beforeReview={this.beforeReview}
                    workoutId={this.state.workoutId}
                    current_attendee={this.state.current_attendee}
                />
                <Helmet>
                    <title>{constand.LIVECLASS_TITLE}{this.state.type}{constand.BEAM}</title>
                    <meta property="og:title" content={constand.LIVECLASS_TITLE + this.state.type + constand.BEAM} />
                    <meta property="og:description" content={constand.LIVECLASS_DESC} />
                    <meta property="og:image" content={constand.LIVECLASS_PAGE_IMAGE} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:site_name" content="Beam" />
                    <meta name="twitter:image:alt" content={constand.LIVECLASS_PAGE_IMAGE_ALT} />
                </Helmet>
                <AdminBanner condition={this.state.type} />
                {!this.state.type.toLowerCase().includes('research') &&
                    <div className="container">
                        <div className="on-liveclasses bg-white banner-border p-t-50 m-b-20">
                            <div className="row">
                                <div className="top-content-live col-sm-9">
                                    <h4 className="m-b-30 font-semibold ash-txt text-left ml-0 font-24">Live class schedule</h4>
                                    <p className="font-18 m-b-25 font-qregular text-left ml-0 black-txt">
                                    Live classes allow you to experience the feel-good
                                    energy of a live class wherever you are.
                                    </p>
                                    <p className="font-18 m-b-30 text-left ml-0 font-qregular black-txt">
                                    Just like our on-demand sessions, live classes are
                                    run by specialist physiotherapists and instructors who share your medical condition.
                                    We want you to know that they understand you and they've got your back, every minute of every workout.
                                    </p>
                                    
                                </div>
                                <div className="col-sm-2 offset-sm-1 col-6 offset-3 text-right d-flex align-items-center align-items-sm-end flex-column justify-content-between">
                                    <Link to="/liveClass"
                                        className="btn bg-dark-blue font-14 text-white fw-bolder br-30 font-book m-t-0 pt-2 pb-2 px-4">
                                        Learn more
                                    </Link>
                                    <figure class="m-b-0 m-t-20 d-flex justify-content-center justify-content-sm-end live-classes-banner">  
                                        <img
                                            src={constand.WEB_IMAGES + "live_classes.png"}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="list_section float-left w-100 p-t-5 p-b-20">
                    <div className="container">
                        {this.renderListData()}
                    </div>
                </div>
                <JoinClassComponent is_model_open={this.state.modelState} modelData={this.state.modelData} modelClose={this.modelClose} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_auth: state.auth.is_auth,
        logged_userData: state.header.logged_userData,
        is_create_mode: state.header.is_create_mode,
        liveclass_filter_instructor: state.liveclass.liveclass_filter_instructor,
    };
};

const mapDispatchToProps = {
    fetchClassList, start_loader, stop_loader, joinClass, loginModelOpen, cancelClass, getInstructor, applyLiveclassFilter

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveClassesComponent);
