import React from "react";
import { connect } from "react-redux";
import { start_loader, stop_loader, fetchHelp, setCondition } from "../../actions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { commonService } from "../../_services";

class HelpComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search: '' };
    this.props.history.listen((location, action) => {
      this.clearState();
    });
    this.clearState = this.clearState.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getHelPContent();
  }

  clearState() {
    this.setState({ search: '' }, function () { this.getHelPContent() });
  }

  handleChange(e) //for twoway binding
  {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  getHelPContent() {
    this.props.start_loader();
    this.props.fetchHelp(this.props.match.params.type).then(
      response => {
        if (response) {
          this.props.stop_loader();
        }
      },
      error => {
        this.props.stop_loader();
        toast.error(error);
      }
    );
  }

  showMobileListName() {
    var label;
    /* if (this.props.match.params.type === 'faq') {
      label = 'FAQ';
    } else { */
      if (this.props.match.params.type) {
        label = commonService.replaceChar(this.props.match.params.type, true);
      }
   // }
    return label;
  }

  render() {
    return (
      <section className="help  m-t-70">
        <div className="help-bg text-center p-t-10 p-b-90" id="help">
          <div className="container">
            <h3 className="font-medium font-37 black-txt p-t-40 p-b-100">
              How can we help?
            </h3>
            <div className="input-group mx-auto w-75 search-btn">
              <input
                onChange={this.handleChange} value={this.state.search}
                type="text"
                className="form-control font-14 font-qregular"
                name="search"
                placeholder="What are you looking for?"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-secondary font-14 font-medium"
                  type="button"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="help_tabs m-t-60 m-b-60">
          <div className="container-fluid mx-auto w-75">
            {/* web view */}
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-3 d-none d-sm-none d-md-block d-lg-block">
                <ul
                  className="nav nav-pills flex-column"
                  id="myTab"
                  role="tablist"
                >
                  {this.props.healthcondition_list &&
                    this.props.healthcondition_list.map((item, key) => {
                      return (
                        <li className="nav-item" key={key}>
                          <Link
                            className={
                              (this.props.match.params.type ===
                                commonService.replaceChar(item.tag, false)
                                ? "active"
                                : "") + " nav-link font-medium font-21 m-b-10"
                            }
                            to={"/help/" + commonService.replaceChar(item.tag, false)}
                            onClick={() => {
                              this.props.setCondition(item.tag);
                            }}
                          >
                            <span className="capitalize_text">{item.tag}</span>
                          </Link>
                        </li>
                      );
                    })}
                  {/* <li className="nav-item">
                    <Link
                      className={
                        (this.props.match.params.type ===
                          'faq'
                          ? "active"
                          : "") + " nav-link font-medium font-21 m-b-10"
                      }
                      to="/help/faq"
                    >
                      FAQ
                    </Link>
                  </li> */}
                </ul>
              </div>
              {/* mobile view */}
              <div className="col-12 mb-3 d-block d-sm-block d-md-none d-lg-none">
                <div className="dropdown tab-btn w-100">
                  <button
                    type="button"
                    className="btn btn-default font-medium font-14 tab-select dropdown-toggle capitalize_text"
                    data-toggle="dropdown"
                  >
                    {this.showMobileListName()}<span className="slt"></span>
                  </button>
                  <div className="dropdown-menu">
                    {this.props.healthcondition_list &&
                      this.props.healthcondition_list.map((item, key) => {
                        return (
                          <Link key={key}
                            className={
                              (this.props.match.params.type ===
                                commonService.replaceChar(item.tag, false)
                                ? "active"
                                : "") + " nav-link font-medium font-21 m-b-10"
                            }
                            to={"/help/" + commonService.replaceChar(item.tag, false)}
                            onClick={() => {
                              this.props.setCondition(item.tag);
                            }}
                          >
                            <span className="capitalize_text">{item.tag}</span>
                          </Link>
                        );
                      })}
                    {/* <Link
                      className={
                        (this.props.match.params.type ===
                          'faq'
                          ? "active"
                          : "") + " nav-link font-medium font-21 m-b-10"
                      }
                      to="/help/faq"
                    >
                      <span>FAQ</span>
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-8 ">
                <div className="general-section">
                  <h4 className="orangefont font-24 font-semibold font-normal m-b-20">
                    Lorem ipsum dolor sit amet?
                  </h4>
                  <p className="font-qregular font-14 black-txt">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam euismod ligula ac sapien iaculis, non tristique lorem
                    egestas. Fusce eget ex sit amet sapien ultricies porta vel
                    sit amet nulla. Ut id lacus porta, malesuada purus at,
                    viverra mi. Sed venenatis dui leo, eget finibus purus
                    egestas ac. Morbi eu ultricies neque, vitae facilisis magna.
                    Nam et urna quam. Nullam aliquam, mauris ac commodo
                    vestibulum, risus quam ornare tortor, vel sodales ligula ex
                    sit amet mi. Curabitur pellentesque nulla ut tempus tempus.
                    Duis sit amet magna sit amet ipsum placerat varius.
                    <br />
                    Integer orci tortor, aliquet sit amet neque vel, consequat
                    accumsan libero. Aenean ultricies turpis vitae semper
                    egestas. Sed ex nunc, commodo in feugiat eget, sagittis eget
                    sem. Mauris diam orci, feugiat vel egestas quis, laoreet sit
                    amet arcu. Nulla vel commodo augue. Donec convallis metus
                    tempor, dapibus ante et, scelerisque neque. Nullam et
                    aliquam nulla. Integer luctus imperdiet gravida.
                    Pellentesque molestie lorem at diam lacinia venenatis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    healthcondition_list: state.register.healthcondition_list,
    userData: state.header.logged_userData
  };
};

const mapDispatchToProps = { start_loader, stop_loader, fetchHelp, setCondition };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpComponent);
